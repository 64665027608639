<template>
  <div class="bg-white">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 my-4">
          <div class="d-flex justify-content-center align-items-center">
            <div>
              <h1>
                <strong class="mb-blue">
                  Don't miss this upgrade opportunity!
                </strong>
              </h1>
              <p class="md-larger">
                We've received your <strong>verified information</strong> for
                the {{ site.sitename }}.
              </p>
              <p class="pb-0 mb-0 md-larger">Upgrade your listing now:</p>
              <ul class="md-larger">
                <li>Appear in unlimited categories</li>
                <li>Feature your company logo on your listing</li>
                <li>Unlock access to review generation tools</li>
              </ul>
              <p class="md-larger">
                Choose your listing type and start reaching more customers
                today!
              </p>
            </div>

            <div class="ms-auto d-none">
              <img
                :src="`https://cdn.mediabrains.com/directorylogos/${site.projectname}_pubhome.gif`"
                class="pb-2 pb-lg-0"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12"></div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.mb-green {
  color: #15c39a;
}
</style>

<script>
export default {
  name: "upgrade.tops.verify",
  props: {
    site: Object,
  },
};
</script>
