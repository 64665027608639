<template>
  <b-modal
    size="lg"
    id="upgrade-unavailable-modal"
    @show="alert"
    :hide-header="true"
    :hide-footer="true"
  >
    <template #default="{ close }">
      <div class="modal-status bg-danger"></div>

      <button type="button" class="btn-close" @click.prevent="close()"></button>

      <!-- Download SVG icon from http://tabler-icons.io/i/alert-triangle -->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon mb-2 text-danger icon-lg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M12 9v2m0 4v.01"></path>
        <path
          d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"
        ></path>
      </svg>
      <h3>We can't complete your purchase right now.</h3>
      <div class="mb-3">
        We apologize. We can't complete your purchase online right now. Our
        customer support team is always available to help, and can help you
        finalize this purchase over the phone or via email.
      </div>
      <div>
        <p><strong>Contact Customer Service</strong></p>
      </div>
      <div class="row mb-3 d-flex justify-items-stretch">
        <div class="col-6">
          <div
            class="d-flex flex-column align-items-center border border-round p-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="big-icon"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path
                d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2"
              ></path>
            </svg>

            <div>Speak with an account manager</div>

            <h3 class="mb-0 pb-0">
              <a href="tel:1-866-627-2467">+1 (866) 627-2467</a>
            </h3>
            <small class="text-muted">Available M-F, 9:00am-5:00pm EST</small>
          </div>
        </div>
        <div class="col-6">
          <div
            class="d-flex flex-column align-items-center justify-content-center border border-round p-2 h-100"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="big-icon"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <line x1="10" y1="14" x2="21" y2="3"></line>
              <path
                d="M21 3l-6.5 18a0.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a0.55 .55 0 0 1 0 -1l18 -6.5"
              ></path>
            </svg>
            <div>Email an account manager</div>
            <h3 class="mb-0 pb-0">
              <a href="mailto:advertisersupport@mediabrains.com">
                advertisersupport@mediabrains.com
              </a>
            </h3>
          </div>
        </div>
      </div>
      <div>
        <p class="text-muted small mb-0 pb-0">
          Our team has been automatically notified and will reach out as soon as
          possible to resolve this issue.
        </p>
      </div>
    </template>
  </b-modal>
</template>

<style lang="scss" scoped>
.big-icon {
  width: 48px;
  height: 48px;
}
</style>

<script>
import BillingService from "../../services/BillingService";
export default {
  name: "upgrade.upgrade-unavailable",
  props: {
    notify: Boolean,
  },
  methods: {
    async alert() {
      if (this.notify) {
        BillingService.sendUnableToUpgrade(this.$auth.activeCompany);
      }
    },
  },
};
</script>
