<template>
  <div class="bg-white shadow-text py-3">
    <div class="container">
      <div
        :class="[
          'row',
          'align-items-center',
          override('logo', 'show', '1', true)
            ? 'justify-content-between'
            : 'justify-content-center',
        ]"
      >
        <div
          class="col-lg-4 d-none d-lg-flex flex-column align-items-center justify-content-center"
          v-if="override('logo', 'show', '1', true)"
        >
          <img
            :src="`https://cdn.mediabrains.com/directorylogos/${site.projectname}_pubhome.gif`"
            class="mb-2 mb-lg-4"
          />
        </div>
        <div
          class="col-lg-8 d-flex flex-column align-items-center justify-content-center"
        >
          <h1 v-if="override('extra', 'show', '1', true)" class="text-center">
            {{
              renderTemplate(
                override("extra", "template", "$0 for your first month"),
                { date: prettyDate(endDate) }
              )
            }}
          </h1>

          <h1
            v-if="override('main', 'show', '1', true)"
            class="text-center mb-blue bigger constrain pb-3"
          >
            {{
              renderTemplate(
                override(
                  "main",
                  "template",
                  "Get all the benefits of a Featured Listing FREE for 30 days"
                ),
                { date: prettyDate(endDate) }
              )
            }}
          </h1>

          <p v-if="override('small', 'show', '0', true)" class="h3">
            {{
              renderTemplate(
                override(
                  "small",
                  "template",
                  "This is an additional element you can control!"
                ),
                { date: prettyDate(endDate) }
              )
            }}
          </p>

          <p
            v-if="override('link.top', 'show', '0', true)"
            :class="
              ['text-center'].concat(
                override('link.top', 'style', '').split(' ')
              )
            "
          >
            <a
              :href="
                renderTemplate(
                  override('link.top.href', 'template', 'https://google.com'),
                  { date: prettyDate(endDate) }
                )
              "
            >
              {{
                renderTemplate(
                  override(
                    "link.top",
                    "template",
                    "This is an additional element you can control!"
                  ),
                  { date: prettyDate(endDate) }
                )
              }}
            </a>
          </p>

          <div
            class="d-flex align-items-center justify-content-center countdown pb-3"
            v-if="override('countdown', 'show', '1', true) && future(endDate)"
          >
            <div class="me-4">
              <h2 class="text-center mb-0">{{ countdown.days }}</h2>
              <span class="subheader text-center">DAYS</span>
            </div>
            <div class="me-4">
              <h2 class="text-center mb-0">{{ countdown.hours }}</h2>
              <span class="subheader text-center">HOURS</span>
            </div>
            <div class="me-4">
              <h2 class="text-center mb-0">{{ countdown.minutes }}</h2>
              <span class="subheader text-center">MINUTES</span>
            </div>
            <div class="">
              <h2 class="text-center mb-0">{{ countdown.seconds }}</h2>
              <span class="subheader text-center">SECONDS</span>
            </div>
          </div>

          <p
            class="text-center text-muted"
            v-if="override('expires', 'show', '1', true) && endDate"
          >
            {{
              renderTemplate(
                override("expires", "template", "Offer expires [date]."),
                { date: prettyDate(endDate) }
              )
            }}
          </p>

          <p
            v-if="override('link.bottom', 'show', '0', true)"
            :class="
              ['text-center'].concat(
                override('link.bottom', 'style', '').split(' ')
              )
            "
          >
            <a
              :href="
                renderTemplate(
                  override(
                    'link.bottom.href',
                    'template',
                    'https://google.com'
                  ),
                  { date: prettyDate(endDate) }
                )
              "
            >
              {{
                renderTemplate(
                  override(
                    "link.bottom",
                    "template",
                    "This is an additional element you can control!"
                  ),
                  { date: prettyDate(endDate) }
                )
              }}
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.verified {
  background: rgba(25, 135, 34, 0.1);
  border-radius: 8px;
  padding: 6px;
  display: inline-flex;
  align-items: center;
  color: rgb(25, 135, 34);
  border: solid 1px rgb(25, 135, 34);
  margin-left: 8px;
  margin-right: 8px;
}

.center-text {
  display: flex;
  align-items: center;
}

.vh100 {
  height: 100vh;
}

.countdown {
  h2 {
    line-height: 20px !important;
  }
  .subheader {
    font-size: 14px !important;
    line-height: 20px !important;
  }
}

h1 {
  font-weight: bolder;
}
</style>

<script>
import {
  parse,
  format,
  differenceInSeconds,
  isFuture,
  setHours,
} from "date-fns";

export default {
  name: "upgrade.tops.custom",
  props: {
    price: Number,
    site: Object,
  },
  data() {
    return {
      endDate: null,
      countdown: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      timer: null,
      showCountdown: true,
    };
  },
  methods: {
    override(key, parameter, _default, bool = false) {
      let debug = this.$route.query.showDebug == "1";
      let overridePath = `${key}.${parameter}`;
      let fromRoute = this.$route.query[overridePath];
      if (debug && bool) {
        console.log(
          `DEBUG: [${overridePath}]->[d:(${_default}) | r:(${fromRoute})]`
        );
      }

      if (debug && !bool) {
        return `[${overridePath}]->[d:(${_default}) | r:(${fromRoute})]`;
        // .replaceAll("[", "{")
        // .replaceAll("]", "}");
      } else if (fromRoute) {
        if (bool) {
          return fromRoute == "1";
        }
        return fromRoute;
      } else {
        if (bool) {
          return _default == "1";
        }
        return _default;
      }
    },
    renderTemplate(template, replacements) {
      replacements["directory.name"] = this.site.site;
      replacements["directory.url"] = `https://${this.site.directoryurl}/`;
      replacements[
        "directory.logo_url"
      ] = `https://cdn.mediabrains.com/directorylogos/${this.site.projectname}_pubhome.gif`;

      replacements["company.name"] = this.$auth.activeCompany.companyName;
      replacements[
        "company.directory_profile_url"
      ] = `https://${this.site.directoryurl}/company/${this.$auth.activeCompany.companyId}`;

      Object.keys(replacements).forEach((replacement) => {
        template = template.replace(
          `[${replacement}]`,
          replacements[replacement]
        );
      });

      return template;
    },
    prettyDate(date) {
      if (date) {
        return format(date, "MMMM do, yyyy");
      }
    },
    future(date) {
      if (date) {
        return isFuture(date);
      } else {
        return false;
      }
    },
    updateCountdown() {
      const diffInSeconds = differenceInSeconds(
        this.endDate,
        new Date().getTime()
      );
      const ONE_DAY = 60 * 60 * 24;
      const ONE_HOUR = 60 * 60;
      const ONE_MINUTE = 60;
      if (diffInSeconds <= 1) {
        this.countdown = {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        };
      }
      const days = Math.floor(diffInSeconds / ONE_DAY);
      const hours = Math.floor((diffInSeconds - days * ONE_DAY) / ONE_HOUR);
      const minutes = Math.floor(
        (diffInSeconds - days * ONE_DAY - hours * ONE_HOUR) / ONE_MINUTE
      );
      const seconds =
        diffInSeconds -
        days * ONE_DAY -
        hours * ONE_HOUR -
        minutes * ONE_MINUTE;
      this.countdown = {
        days,
        hours,
        minutes,
        seconds,
      };
    },
  },
  mounted() {
    if (this.$route.query.showCountdown) {
      this.showCountdown = this.$route.query.showCountdown == "1";
    }

    if (this.$route.query.end) {
      this.endDate = setHours(
        parse(this.$route.query.end, "MM-dd-yyyy", new Date()),
        17
      );
      if (!this.timer) {
        this.updateCountdown();
        setInterval(() => this.updateCountdown(), 1000);
      }
    }
  },
};
</script>
