import APIService from "./APIService";
import { getInstance } from "../auth/auth";

const http = APIService.http;

export default {
  async getInsertionOrderForIncident(incidentId) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.get(
      `api/insertionOrder/insertion-order/${incidentId}`,
      authHeaders
    );
    return res.data;
  },

  async getInsertionOrderDetail(insertionorderid, company) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post(
      `api/insertionOrder/insertion-order-description/${insertionorderid}`,
      company,
      authHeaders
    );
    return res.data;
  },

  async getUpgradePreview(company, site, product, duration, upgrading, trial) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let url = `api/insertionOrder/preview-upgrade/${company}/${site}/${product}/${duration}/${trial}/`;
    if (upgrading) {
      url += upgrading;
    }
    let res = await http.get(url, authHeaders);
    return res.data;
  },
};
