import APIService from "./APIService";
import { getInstance } from "../auth/auth";

const http = APIService.http;

export default {
  domainToMasked(domain) {
    var a = "abcdefghijklmnopqrstuvwxyz";
    var fakeDomain = "";
    for (var i = 0; i < domain.length; i++) {
      if (a.indexOf(domain.charAt(i)) >= 0) {
        fakeDomain += a.charAt(Math.floor(Math.random() * a.length));
      } else {
        fakeDomain += domain.charAt(i);
      }
    }
    return fakeDomain;
  },
  planIncludes(feature, companyPlan) {
    let auth = getInstance();
    return auth.planIncludes(feature, companyPlan);
  },
  async generateInvoice(
    company,
    site,
    product,
    upgrading,
    incident,
    lpUrl,
    duration,
    rep,
    trial
  ) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let url = `api/Upgrades/create-invoice/${company}/${site}/${product}/${duration}/${trial}/`;
    if (upgrading) {
      url += `${upgrading}/`;
    } else {
      url += "null/";
    }

    if (incident) {
      url += `${incident}/`;
    } else {
      url += "null/";
    }
    let res = await http.post(url, { lp: lpUrl, rep: rep }, authHeaders);
    return res.data;
  },
  async createIncident(company, site) {
    const auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post(
      "api/Upgrades/incident",
      {
        company: company,
        site: site,
      },
      authHeaders
    );
    return res.data;
  },
  async checkout(company, site, incident, card) {
    const auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post(
      "api/Upgrades/checkout",
      {
        company: company,
        site: site,
        incident: incident,
        card: card,
      },
      authHeaders
    );
    return res.data;
  },
  async getUpgradeRebate(company, site) {
    const auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post(
      "api/Upgrades/info",
      {
        company: company,
        site: site,
      },
      authHeaders
    );
    return res.data;
  },
};
