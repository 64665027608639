<template>
  <div class="bg-white">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 my-4">
          <div class="alert alert-success">
            We've received your request to be listed in the {{ site.sitename }}.
            You're one step closer to connecting with in-market professionals.
          </div>

          <div class="justify-content-center d-flex align-items-center">
            <div class="p-4 mb-orange">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class=""
                width="48"
                height="48"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M9 12l2 2l4 -4"></path>
                <path
                  d="M12 3a12 12 0 0 0 8.5 3a12 12 0 0 1 -8.5 15a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3"
                ></path>
              </svg>
            </div>
            <div>
              <h2>
                <strong class="mb-orange">What's next?</strong> We verify our
                listings.
              </h2>
              <p>
                <strong>Step 1:</strong> We <strong>confirm</strong> your
                company details with you over the phone.
                <br />
                <strong>Step 2:</strong> We <strong>build</strong> your company
                profile. <br />
                <strong>Step 3:</strong> Your listing goes
                <strong>live</strong>!
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12"></div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.mb-orange {
  color: #f68b26;
}
</style>

<script>
export default {
  name: "upgarde.tops.add-your-company",
  props: {
    site: Object,
  },
};
</script>
