<template>
  <div :class="[size ? cardSize : '', 'mb-4', 'mb-lg-0', order]">
    <div
      :class="[
        'card',
        'position-relative',
        `price-card-${color}`,
        active ? `active` : ``,
        disabled ? `disabled` : ``,
      ]"
    >
      <div class="card-status-top bg-color"></div>
      <div
        class="d-flex align-items-center justify-content-center position-absolute top-0 start-50 translate-middle-x"
        v-if="popular"
      >
        <span class="text-center small lh-1 rounded text-white bg-color p-2">
          {{ popular }}
        </span>
      </div>
      <div class="card-body d-flex flex-column">
        <h1 class="text-center mt-extra">{{ name }}</h1>

        <template v-if="price !== undefined">
          <template v-if="trial">
            <div class="no-price lh-1" v-if="price > 0">
              <strong class="fs-2 my-1">First month free</strong>
              <small> Then billed monthly at ${{ commas(price) }}. </small>
              <small class="text-muted time">
                Risk free trial - cancel online any time.
              </small>
            </div>
            <template v-else>
              <div class="price">
                <h3>{{ commas(price) }}</h3>
                <div class="stack">
                  <span class="currency">$</span>
                  <span class="time">{{ frequency }}</span>
                </div>
              </div>
            </template>
          </template>
          <template v-else>
            <div class="price">
              <h3>{{ commas(price) }}</h3>
              <div class="stack">
                <span class="currency">$</span>
                <span class="time">{{ frequency }}</span>
              </div>
            </div>
          </template>
        </template>
        <div v-else class="no-price">
          <span class="message">Build your ideal marketing solution.</span>
          <span class="time">{{ frequency }}</span>
        </div>

        <div class="mt-3"></div>

        <button class="btn outline select mb-3 disabled" v-if="current">
          <span>Current plan</span>
        </button>
        <button
          v-else-if="!active && !disabled"
          @click.prevent="$emit('selected') && $event.target.blur()"
          class="btn btn-primary select mb-3"
        >
          {{ buttonText }}
        </button>
        <button
          v-else-if="disabled"
          class="btn btn-outline select mb-3 plan-disabled disabled"
        >
          Not available
        </button>
        <button v-else class="btn btn-outline select selected mb-3 disabled">
          <span>Selected plan</span>
        </button>

        <strong v-if="inherits" class="inherits mb-3">
          Everything in {{ inherits }} plus:
        </strong>

        <ul class="features">
          <li
            v-for="(feature, idx) in features"
            :key="`${name}-${idx}-plan-feature`"
          >
            {{ feature }}
          </li>
        </ul>

        <a
          href="#"
          @click.prevent="showFeatures = !showFeatures"
          class="d-block d-lg-none mb-3 mt-3 text-center all-features"
          v-if="upgrades"
        >
          See All Features

          <svg
            v-if="!showFeatures"
            xmlns="http://www.w3.org/2000/svg"
            :class="['icon', `icon-color`]"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <desc>
              Download more icon variants from
              https://tabler-icons.io/i/chevron-down
            </desc>
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <polyline points="6 9 12 15 18 9"></polyline>
          </svg>
          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            :class="['icon', `icon-color`]"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <desc>
              Download more icon variants from
              https://tabler-icons.io/i/chevron-up
            </desc>
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <polyline points="6 15 12 9 18 15"></polyline>
          </svg>
        </a>
        <transition name="slide">
          <upgrades-table
            v-if="upgrades && showFeatures"
            :forPlan="slug"
            :upgrades="upgrades"
          ></upgrades-table>
        </transition>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$colors: (
  "blue": gray,
  "orange": #f68b26,
  "green": #15c39a,
  "purple": #6e66de,
);

@mixin price-card($accent) {
  .card-status-top {
    height: 6px;
  }

  .mt-extra {
    margin-top: 36px;
  }

  .bg-color {
    background: $accent;
  }

  h1 {
    color: $accent;
  }

  .inherits {
    font-size: 16px;
  }

  .all-features {
    color: #22343d;
    .icon-color {
      color: $accent;
    }
  }

  .features {
    list-style: none;

    li {
      position: relative;
      padding-left: 8px;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 9px;
    }

    li:before {
      content: "";
      position: absolute;
      top: 4px;
      left: -20px;
      width: 18px;
      height: 20px;
      background-color: $accent;
      background-repeat: no-repeat;
      mask-image: url("../../assets/check.svg");
      mask-size: 18px 20px;
      background-size: 18px 20px;
    }
  }

  .select {
    color: white;
    font-weight: 600;
    background: $accent;
    border: solid white 2px;

    &.outline {
      color: $accent;
      background: white;
      border: solid $accent 2px;
    }

    &.selected {
      background: $accent;
      color: white;
      border: solid $accent 2px;
    }

    &.plan-disabled {
      color: white;
      background-color: #cdd3dd;
      border-color: #cdd3dd;
    }

    &:hover {
      background: $accent;
      color: white;
      border: solid $accent 2px;
    }
  }

  .no-price {
    height: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .message {
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 4px;
    }

    .time {
      color: #737c88;
      font-size: 12px;
      line-height: 12px;
      overflow-wrap: normal;
    }
  }

  .price {
    display: flex;
    align-items: center;
    justify-content: center;

    h3 {
      font-size: 54px;
      margin-right: 3px;
      margin-bottom: 0px;
      line-height: 60px;
      font-variant-numeric: tabular-nums;
    }

    .stack {
      display: flex;
      flex-direction: column;

      .currency {
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
      }

      .time {
        color: #737c88;
        font-size: 12px;
        line-height: 12px;
        max-width: 80px;
        overflow-wrap: normal;
      }
    }
  }
}

@each $name, $color in $colors {
  .price-card-#{$name} {
    &.active {
      border-color: $color;
    }

    &.disabled {
      background-color: #f6f6f6;
    }

    @include price-card($color);
  }
}

.slide-enter-active {
  transition: all 0.3s ease;
}
.slide-leave-active {
  transition: all 0s ease-out;
}
.slide-enter {
  transform: translate(0, 100%);
  opacity: 0;
}
.slide-leave-to {
  transform: translateY(100px);
  opacity: 0;
}
</style>

<script>
import UpgradesTable from "./UpgradesTable.vue";

export default {
  components: { UpgradesTable },
  name: "upgrade.pricing-card",
  props: {
    inherits: String,
    name: String,
    price: Number,
    color: String,
    frequency: String,
    features: Array,
    buttonText: String,
    active: Boolean,
    current: Boolean,
    disabled: Boolean,
    popular: String,
    upgrades: Array,
    slug: String,
    size: Boolean,
    trial: Boolean,
  },
  data() {
    return {
      showFeatures: false,
      cardClass: null,
    };
  },
  computed: {
    cardSize() {
      if (this.cardClass) {
        return this.cardClass;
      }
      return "col-lg-3";
    },
    order() {
      const orders = {
        basic: "order-2 order-lg-0",
        featured: "order-0 order-lg-2",
        optimized: "order-1 order-lg-1",
        custom: "order-3 order-lg-3",
      };
      return orders[this.name.toLowerCase()];
    },
  },
  methods: {
    commas(price) {
      return price.toLocaleString();
    },
  },
  mounted() {
    if (this.$route.query.cardClass) {
      this.cardClass = this.$route.query.cardClass;
    }
  },
};
</script>
