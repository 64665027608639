<template>
  <div class="newupgrade">
    <div class="hide-loader" v-if="scrollHidden"></div>
    <div class="extra-space-bottom lp-fonts" ref="lp" v-if="loaded">
      <div class="bg-white" v-if="showTop">
        <div class="container">
          <div class="row align-items-center justify-content-between py-lp">
            <div class="col-lg-5 d-flex flex-column align-items-center">
              <img
                :src="`https://cdn.mediabrains.com/directorylogos/${site.projectname}_pubhome.gif`"
                class="pb-2 pb-lg-0"
              />
            </div>
            <div class="col-lg-7">
              <h1 class="text-center text-lg-start">
                Show Up Where It Matters Most
              </h1>
              <p class="subheader text-center text-lg-start">
                Make sure motivated buyers find your products and services in
                the
                {{ site.sitename }}.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="topComponent">
        <component
          :is="topComponent"
          :site="site"
          :price="
            showMonthlyPrice
              ? products.featured.monthlyPrice
              : Math.ceil(products.featured.annualPrice / plan.costDivisor)
          "
        ></component>
      </div>

      <div class="mt-2" id="plans" v-if="showPricing">
        <div class="container pt-4">
          <div class="row">
            <div
              class="col-md-12 d-flex flex-wrap justify-content-center justify-content-lg-between align-items-stretch"
            >
              <h1 class="text-center mb-3 mb-lg-0">{{ title }}</h1>

              <div class="duration">
                <button
                  @click="changeDuration(plans[plan].duration)"
                  :class="[
                    duration == plans[plan].duration ? 'active' : '',
                    'position-relative',
                  ]"
                  v-for="(plan, idx) in availablePlans"
                  :key="`plan-toggle-plan-${idx}`"
                >
                  {{ plans[plan].name }}

                  <span
                    class="callout start-50 translate-middle"
                    v-if="plans[plan].savings"
                  >
                    {{ plans[plan].savings }}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="container py-lp">
          <div class="row row-cards row-deck justify-content-center">
            <pricing-card
              color="blue"
              name="Basic"
              :price="0"
              :active="false"
              :current="currentPlan.planName == 'basic'"
              @selected="noPlan ? selectFreeNoPlan() : addToCart('basic')"
              frequency="Yep, it's free!"
              :features="features['basic']"
              :slug="'basic'"
              :buttonText="`Select Plan`"
              :size="true"
              :trial="trial"
              v-if="!hidden('basic')"
            ></pricing-card>

            <pricing-card
              color="green"
              name="Optimized"
              :price="
                showMonthlyPrice
                  ? products.optimized.monthlyPrice
                  : Math.ceil(products.optimized.annualPrice / plan.costDivisor)
              "
              :active="selected == 'optimized'"
              :current="currentPlan.planName == 'optimized'"
              :frequency="frequency"
              @selected="addToCart('optimized')"
              :features="features['optimized']"
              :inherits="hidden('basic') ? null : `Basic`"
              :buttonText="`Select Plan`"
              :slug="'optimized'"
              :upgrades="fullFeatures"
              :size="true"
              :trial="trial"
              v-if="!hidden('optimized')"
            ></pricing-card>

            <pricing-card
              color="orange"
              name="Featured"
              :price="
                showMonthlyPrice
                  ? products.featured.monthlyPrice
                  : Math.ceil(products.featured.annualPrice / plan.costDivisor)
              "
              :frequency="frequency"
              :active="selected == 'featured'"
              :current="currentPlan.planName == 'featured'"
              :features="features['featured']"
              :inherits="
                hidden('optimized')
                  ? hidden('basic')
                    ? null
                    : `Basic`
                  : `Optimized`
              "
              :popular="'Most popular'"
              @selected="addToCart('featured')"
              :buttonText="`Select Plan`"
              :slug="'featured'"
              :upgrades="fullFeatures"
              :size="true"
              :trial="trial"
              v-if="!hidden('featured')"
            ></pricing-card>

            <pricing-card
              color="purple"
              name="Custom"
              frequency="Billed annually"
              :inherits="hidden('featured') ? null : `Featured`"
              :active="selected == 'custom'"
              :current="currentPlan.planName == 'custom'"
              :features="features['custom']"
              :buttonText="`Contact sales`"
              @selected="contact"
              :size="true"
              :trial="trial"
              v-if="!hidden('custom')"
            ></pricing-card>
          </div>
        </div>
      </div>

      <div class="bg-white d-none d-lg-block">
        <div class="container py-lp">
          <div class="row mb-4">
            <div class="col d-flex align-items-center flex-column">
              <h1>What you get</h1>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <upgrades-table
                :forPlan="
                  hide.length > 0
                    ? ['basic', 'optimized', 'featured']
                        .filter((x) => !hide.includes(x))
                        .join(',')
                    : `all`
                "
                :current="currentPlan ? currentPlan.planName : null"
                :upgrades="fullFeatures"
                @cart="addToCart"
              ></upgrades-table>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-white d-block d-lg-none">
        <div class="p-2"></div>
      </div>

      <div class="position-relative bg-white no-overflow">
        <div class="container position-relative z-top my-lp">
          <div class="row">
            <div class="col-12 d-flex align-items-center flex-column">
              <div class="subheader text-capitalize">
                Don't take our word for it
              </div>
              <h1 class="constrain tighter text-center">
                See what our customers are saying
              </h1>
            </div>
          </div>
        </div>

        <div class="container position-relative z-top pb-4">
          <div class="row mb-4 justify-content-evenly align-items-stretch">
            <div class="col-md-4 col-lg-3 testimonial mb-4 mb-md-0">
              <h3 class="title">Great return on our investment</h3>
              <p class="review">
                Next to our own website, the SHRM Vendor Directory is the
                highest lead generator we found. The Featured placement is the
                best money we've ever spent.
              </p>

              <div class="lh-1">
                <div class="mb-2">
                  <img src="@/assets/lp/fivestars.svg" />
                </div>
                <div><strong>HudsonMann</strong></div>
                <div class="mt-1 small text-muted">Director of Marketing</div>
              </div>
            </div>

            <div class="col-md-4 col-lg-3 testimonial mb-4 mb-md-0">
              <h3 class="title">Reaching the right audience pays off</h3>
              <p class="review">
                I got a call from a nationwide engineering firm a couple of
                weeks after our listing went live. Not only did the new business
                pay for the advertising but I still made money off that one
                call!
              </p>

              <div class="lh-1">
                <div class="mb-2">
                  <img src="@/assets/lp/fivestars.svg" />
                </div>
                <div><strong>Acumen Performance Group</strong></div>
                <div class="mt-1 small text-muted">Vice President</div>
              </div>
            </div>

            <div class="col-md-4 col-lg-3 testimonial">
              <h3 class="title">Motivated buyers are here</h3>
              <p class="review">
                We have received 61 leads from MediaBrains after listing in the
                SHRM directory, and have converted 50% of those leads into new
                business.
              </p>

              <div class="lh-1">
                <div class="mb-2">
                  <img src="@/assets/lp/fivestars.svg" />
                </div>
                <div><strong>TalentKeepers</strong></div>
                <div class="mt-1 small text-muted">Director of Sales</div>
              </div>
            </div>
          </div>
        </div>

        <svg
          class="cap"
          viewBox="0 0 1440 360"
          fill="pink"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M727.1 0.00108579C1182.4 -0.543763 1638.88 248.678 1777.51 331.193C1790.31 338.813 1784.68 357.41 1769.78 357.427L-329.297 359.939C-344.237 359.957 -349.887 341.301 -337.028 333.696C-196.89 250.821 264.521 0.554641 727.1 0.00108579Z"
            fill="url(#paint0_linear_256_676)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_256_676"
              x1="-340.498"
              y1="1.21275"
              x2="-340.081"
              y2="361.148"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#F1F2F3" />
              <stop offset="1" stop-color="white" stop-opacity="0.01" />
            </linearGradient>
          </defs>
        </svg>
      </div>

      <div ref="learn-more">
        <div class="container my-lp">
          <div class="row mb-4">
            <div class="col d-flex align-items-center flex-column">
              <h1 class="text-start text-md-center constrain-lg tighter">
                Drive brand awareness, engagement, and lead generation
              </h1>
              <p class="text-start text-md-center constrain-lg">
                Directory advertising is an easy opportunity to call attention
                to your product or service at a time when the customer is
                actively looking and prepared to buy.
              </p>
            </div>
          </div>

          <div class="row row-deck justify-content-evenly mb-lg-4">
            <div class="col-lg-3 mb-4 mb-lg-0">
              <callout-card
                :title="`Get Noticed`"
                :callouts="callouts['noticed']"
                :icon="`megaphone`"
              ></callout-card>
            </div>
            <div class="col-lg-3 mb-4 mb-lg-0">
              <callout-card
                :title="`Gain Trust`"
                :callouts="callouts['trust']"
                :icon="`handshake`"
              ></callout-card>
            </div>
            <div class="col-lg-3">
              <callout-card
                :title="`Grow Traffic & Leads`"
                :callouts="callouts['grow']"
                :icon="`graph`"
              ></callout-card>
            </div>
          </div>

          <div class="row pt-4">
            <div
              class="col-12 d-flex align-items-center justify-content-center justify-items-center"
            >
              <a href="#plans" class="btn btn-lg btn-orange"
                >Choose Your Plan</a
              >
            </div>
          </div>
        </div>
      </div>

      <div class="bg-white">
        <div class="container">
          <div class="row align-items-center py-lp justify-content-between">
            <div class="col-md-6">
              <h1>Generate leads from your content</h1>
              <p>
                When buyers are in the consideration stage, they use
                authoritative content to accelerate decision making. Your
                content library notifies you of who has interacted with each
                piece of content and allows you to gauge performance, make
                adjustments and easily upload more of the content that converts.
              </p>
            </div>
            <div
              class="col-md-6 d-flex flex-column align-items-center order-first order-md-last"
            >
              <img class="mw-100 drop-shadow" src="@/assets/lp/content.svg" />
            </div>
          </div>

          <div class="row my-lp align-items-center justify-content-between">
            <div class="col-md-6 d-flex flex-column align-items-center">
              <img class="mw-100" src="@/assets/lp/reviews.svg" />
            </div>

            <div class="col-md-6">
              <h1>Leverage the power of your reviews.</h1>
              <p>
                Unlock Business Chatter® to collect and manage consumer reviews
                about your company and products.
              </p>
              <ul class="check-list">
                <li>
                  Boost brand confidence by easily gathering and sharing reviews
                  from past customers.
                </li>
                <li>
                  Get notifications when a review is created, so that you
                  maintain control of your company's digital reputation.
                </li>
                <li>
                  Drive sales by easily allowing customers to hear what other
                  customers have to say.
                </li>
              </ul>
            </div>
          </div>

          <div class="row py-lp align-items-center justify-content-between">
            <div class="col-md-6">
              <h1>
                Engage your target audience where it matters, when it matters.
              </h1>
              <p>
                Gain full access to our comprehensive array of audience insight
                tools to ensure your products and services are reaching the
                right audience. Use our monthly reports and informative
                dashboard to make data-driven marketing decisions that drive
                high quality leads.
              </p>
            </div>

            <div
              class="col-md-6 d-flex flex-column align-items-center order-first order-md-last"
            >
              <img
                class="mw-100 drop-shadow"
                src="@/assets/lp/audience-report.svg"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="bg-orange">
        <div class="container">
          <div class="row align-items-center justify-content-between py-lp">
            <div class="col-lg-5">
              <h1>
                Attract the Attention of Targeted, Highly Qualified Buyers
              </h1>
              <p>
                A listing in our industry-specific platform establishes your
                brand as the go-to resource in your industry, develops your
                audience, and boosts revenue. Learn how, request a personalized
                demo.
              </p>
              <button
                @click.prevent="contact"
                class="btn btn-light btn-outline btn-outline-only"
              >
                Request A Demo
              </button>
            </div>
            <div
              class="col-md-6 d-none d-lg-flex flex-column align-items-center"
            >
              <img class="drop-shadow-light" src="@/assets/lp/profile.png" />
            </div>
          </div>
        </div>
      </div>

      <div class="">
        <div class="container">
          <div class="row align-items-center justify-content-between py-lp">
            <div
              class="col-lg-5 d-none d-lg-flex flex-column align-items-center"
            >
              <img class="drop-shadow" src="@/assets/lp/testimonial.png" />
            </div>
            <div class="col-lg-7">
              <img class="display-block mb-3" src="@/assets/lp/quote.svg" />
              <p class="quote">
                Being a smaller marketing department, working with MediaBrains
                is like having an extension of our marketing department working
                for us. Actionable insights into our audience led to so many
                leads generated from MediaBrains.
              </p>
              <p class="small d-none d-lg-block">
                Tamara Seiler, Director of Compliance &amp; Marketing Strategy
              </p>
              <div
                class="d-flex d-lg-none lh-1 text-reset p-0 author align-items-center"
              >
                <span class="avatar avatar-sm bg-transparent">
                  <img class="rounded" src="@/assets/lp/tamara.png" />
                </span>
                <div class="ps-2">
                  <div><strong>Tamara Seiler</strong> - HudsonMann</div>
                  <div class="mt-1 small text-muted">
                    Director of Compliance &amp; Marketing Strategy
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-blue">
        <div class="container">
          <div class="row align-items-center justify-content-center py-lp">
            <div
              class="col-md-8 d-flex flex-column align-items-center text-center"
            >
              <h1>Try it. Cancel Anytime.</h1>
              <p class="larger mb-4">
                Add your company for 30 days. If you find that your subscription
                isn't meeting your needs, contact us and we'll downgrade to a
                free listing at anytime.
              </p>
              <a class="btn btn-green" href="#plans">Choose Your Plan</a>
            </div>
          </div>
        </div>
      </div>

      <div class="cart py-3" v-if="cart">
        <div class="container">
          <div
            class="d-flex align-items-center justify-content-center flex-wrap"
          >
            <div class="me-auto">
              <h3 class="subheader mb-0">Summary</h3>
              <h1>{{ cart.planName }} Plan - {{ cart.duration }}</h1>
            </div>

            <div
              class="ms-2 text-muted align-start d-block d-lg-none"
              @click="clearCart"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon close"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </div>

            <div
              class="flex-wrap border-top border-start border-end rounded pt-2 w-lg-auto"
            >
              <div class="d-flex align-items-center justify-content-between">
                <div class="text-start ps-4">
                  <span class="text-muted small lh-1">Renews at</span>

                  <h1 class="mt-0 lh-1">
                    {{ money(cart.price, 0) }}/{{ cart.per }}
                  </h1>
                </div>
                <div class="divider mx-4"></div>
                <div class="text-end ps-3 pe-4">
                  <span class="text-muted small lh-1">Due today</span>

                  <h1 class="mt-0 lh-1" v-if="upgradePreview">
                    <span v-if="upgradePreview.trialOccurances > 0">
                      {{ money(upgradePreview.trialAmount, 2) }}
                    </span>
                    <span v-else>
                      {{ money(upgradePreview.amount, 2) }}
                    </span>
                  </h1>
                  <h1 class="mt-0" v-else>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-loader spin"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <line x1="12" y1="6" x2="12" y2="3"></line>
                      <line x1="16.25" y1="7.75" x2="18.4" y2="5.6"></line>
                      <line x1="18" y1="12" x2="21" y2="12"></line>
                      <line x1="16.25" y1="16.25" x2="18.4" y2="18.4"></line>
                      <line x1="12" y1="18" x2="12" y2="21"></line>
                      <line x1="7.75" y1="16.25" x2="5.6" y2="18.4"></line>
                      <line x1="6" y1="12" x2="3" y2="12"></line>
                      <line x1="7.75" y1="7.75" x2="5.6" y2="5.6"></line>
                    </svg>
                  </h1>
                </div>
              </div>

              <div class="d-grid mt-2 w-100">
                <button
                  v-if="upgradePreview"
                  @click="checkout"
                  :class="['btn', 'btn-primary', buying ? 'disabled' : '']"
                >
                  {{ checkoutButton }}
                </button>
                <button v-else :class="['btn', 'btn-primary', 'disabled']">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-loader spin"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <line x1="12" y1="6" x2="12" y2="3"></line>
                    <line x1="16.25" y1="7.75" x2="18.4" y2="5.6"></line>
                    <line x1="18" y1="12" x2="21" y2="12"></line>
                    <line x1="16.25" y1="16.25" x2="18.4" y2="18.4"></line>
                    <line x1="12" y1="18" x2="12" y2="21"></line>
                    <line x1="7.75" y1="16.25" x2="5.6" y2="18.4"></line>
                    <line x1="6" y1="12" x2="3" y2="12"></line>
                    <line x1="7.75" y1="7.75" x2="5.6" y2="5.6"></line>
                  </svg>
                  Upgrade Now
                </button>
              </div>
            </div>

            <div
              class="ms-2 text-muted align-start d-none d-lg-block"
              @click="clearCart"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon close"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </div>
          </div>
        </div>
      </div>

      <upgrade-unavailable :notify="true"></upgrade-unavailable>

      <b-modal id="book-demo-modal" :hide-footer="true" v-if="rep">
        <template #modal-header="{ close }">
          <h5 class="modal-title">Book a demo</h5>
          <button
            type="button"
            class="btn-close"
            @click.prevent="close"
          ></button>
        </template>

        <vue-calendly
          :url="`https://calendly.com/${rep.calendlyUsername}/custom-plan-demo`"
          :height="600"
        ></vue-calendly>
      </b-modal>
    </div>
  </div>
</template>

<style lang="scss">
@mixin white-text {
  color: white;

  &.shadow-text {
    h1 {
      text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.1);
    }
  }

  .subheader {
    color: white;
  }

  .text-muted {
    color: rgb(237, 237, 237) !important;
  }
}

.newupgrade {
  position: relative;

  .hide-loader {
    position: fixed;
    z-index: 10000000;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgb(245, 247, 251);
  }

  .align-start {
    align-self: start;
  }

  .close {
    color: rgb(202, 202, 202);
    cursor: pointer;
  }

  .spin {
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .extra-space-bottom {
    margin-bottom: 120px;
  }

  .lp-fonts {
    font-size: 16px;
    line-height: 24px;

    h1 {
      font-size: 32px;
      line-height: 42px;

      &.bigger {
        font-size: 36px;
        line-height: 46px;
      }
    }

    h2 {
      font-size: 24px;
      line-height: 34px;
    }

    .subheader {
      text-transform: none;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
    }

    .thin {
      font-weight: 400;
    }
  }

  .my-lp {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  .py-lp {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .mb-orange {
    color: #f68b26;
  }

  .mb-blue {
    color: #2f8dde;
  }

  .bg-orange {
    background-color: #f68b26 !important;

    @include white-text();
  }

  .bg-blue {
    background-color: #2f8dde !important;

    @include white-text();
  }

  .bg-green {
    background-color: #15c39a !important;

    @include white-text();
  }

  .btn-outline-only {
    background: transparent;
    color: white;
    border-color: white;

    &:hover {
      background: white;
      color: black;
    }
  }

  .btn-orange {
    background: #f68b26;
    border-color: #f68b26;
  }

  .btn-green {
    background: #15c39a;
    border-color: #15c39a;
  }

  .constrain {
    max-width: 375px;

    &.tighter {
      max-width: 350px;
    }
  }

  .testimonial {
    .title {
      font-size: 18px;
      line-height: 28px;
    }

    .author {
      .avatar {
        flex-shrink: 0;
        width: 56px;
        height: 56px;
        img {
          width: 56px;
          height: 56px;
        }
      }
    }
  }

  .z-top {
    z-index: 5;
  }

  .cap {
    position: absolute;
    top: 0;
    z-index: 0;
  }

  .no-overflow {
    overflow-y: hidden;
  }

  .quote {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
  }

  .larger {
    font-size: 22px;
    line-height: 33px;
  }

  .w-lg-auto {
    width: 100%;
  }

  .drop-shadow {
    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.1));
    -webkit-filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.1));
  }

  .drop-shadow-light {
    filter: drop-shadow(5px 5px 5px rgba(255, 255, 255, 0.15));
    -webkit-filter: drop-shadow(5px 5px 5px rgba(255, 255, 255, 0.15));
  }

  .check-list {
    li::marker {
      color: #f68b26;
    }
  }

  @media (min-width: 768px) {
    .testimonial {
      display: flex;
      flex-direction: column;

      .review {
        padding-bottom: 16px;
        margin-bottom: auto;
      }
    }
  }

  @media (min-width: 992px) {
    .w-lg-auto {
      width: auto;
    }

    .quote {
      font-weight: 400;
      font-size: 24px;
      line-height: 36px;
    }

    .larger {
      font-size: 22px;
      line-height: 33px;
    }

    .md-larger {
      font-size: 22px;
      line-height: 33px;
    }

    .extra-space-bottom {
      margin-bottom: 120px;
    }

    .lp-fonts {
      font-size: 16px;
      line-height: 24px;

      h1 {
        font-size: 36px;
        line-height: 48px;

        &.bigger {
          font-size: 42px;
          line-height: 54px;
        }
      }

      h2 {
        font-size: 24px;
        line-height: 34px;
      }
    }

    .my-lp {
      margin-top: 64px;
      margin-bottom: 64px;
    }

    .py-lp {
      padding-top: 64px;
      padding-bottom: 64px;
    }

    .constrain,
    .constrain-lg {
      max-width: 700px;

      &.tighter {
        max-width: 600px;
      }
    }
  }

  .cart {
    background: white;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    box-shadow: 0 0 30px 0 rgba(24, 31, 52, 0.35);
    z-index: 900000;

    .subheader {
      font-size: 14px;
      line-height: 18px;
    }

    h1 {
      text-transform: capitalize;
      font-size: 24px;
      line-height: 32px;
    }

    .divider {
      border-left: #d3dae3 solid 1px;
      align-self: stretch !important;
    }
  }

  .duration {
    background: white;
    padding: 5px;
    border: solid 1px #cdd3dd;
    border-radius: 30px;
    width: 260px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    line-height: 18px;

    button {
      background-color: white;
      border: none;
      outline: none;
      text-align: center;
      text-decoration: none;
      border-radius: 20px;
      color: #737c88;
      width: 130px;
      height: 100%;
      transition: background-color 0.2s ease-in;

      &:hover {
        background: #edeef0;
      }

      &.active {
        color: white;
        background-color: #2f8dde;
        font-weight: bold;
      }

      .callout {
        font-weight: bold;
        position: absolute;
        font-size: 10px;
        width: 65px;
        padding: 3px;
        border-radius: 15px;
        color: white;
        bottom: -27px;
        background: #f68b26;
      }
    }
  }
}
</style>

<script>
import PricingCard from "../components/Upgrade/PricingCard.vue";
import CalloutCard from "../components/Upgrade/CalloutCard.vue";
import UpgradeUnavailable from "../components/Upgrade/UpgradeUnavailable.vue";
import InsertionOrderService from "../services/InsertionOrderService";
import SiteService from "../services/SiteService";
import UpsellService from "../services/UpsellService";
import UpgradesTable from "../components/Upgrade/UpgradesTable.vue";
import BillingService from "../services/BillingService";

import AddYourCompany from "../components/Upgrade/tops/AddYourCompany.vue";
import Verify from "../components/Upgrade/tops/Verify.vue";
import SixMonth from "../components/Upgrade/tops/SixMonth.vue";
import SixMonthOrange from "../components/Upgrade/tops/SixMonthOrange.vue";
import VerifyOffer from "../components/Upgrade/tops/VerifyOffer.vue";
import Custom from "../components/Upgrade/tops/Custom.vue";

import TrackingService from "../services/TrackingService";

export default {
  name: "account.billing.upgrade",
  components: {
    PricingCard,
    UpgradeUnavailable,
    CalloutCard,
    UpgradesTable,
    AddYourCompany,
    Verify,
    SixMonth,
    SixMonthOrange,
    VerifyOffer,
    Custom,
  },
  data() {
    return {
      scrollHidden: false,
      duration: "annual",
      availablePlans: ["monthly", "annual"],
      selectedPlan: "annual",
      hide: [],
      plans: {
        monthly: {
          name: "Monthly",
          productSlug: "-monthly",
          duration: "monthly",
          frequencyMessage: "Per month, billed monthly",
          durationMonths: 1,
          costDivisor: 1,
          cartDivisor: 1,
          per: "month",
        },
        "six-month": {
          name: "6 Months",
          productSlug: "-annual",
          duration: "six-month",
          frequencyMessage: "Per month, billed semi-annually",
          durationMonths: 6,
          costDivisor: 12,
          cartDivisor: 2,
          savings: "Save 15%",
          per: "6 months",
        },
        annual: {
          name: "Annual",
          productSlug: "-annual",
          duration: "annual",
          frequencyMessage: "Per month, billed annually",
          durationMonths: 12,
          costDivisor: 12,
          cartDivisor: 1,
          savings: "Save 15%",
          per: "year",
        },
      },
      durationMonths: 12,
      showTop: false,
      showPricing: true,
      products: {
        basic: {
          monthlyPrice: 0,
          annualPrice: 0,
        },
        optimized: {
          monthlyPrice: 139,
          annualPrice: 1395.0,
        },
        featured: {
          monthlyPrice: 397,
          annualPrice: 3975.0,
        },
      },
      callouts: {
        noticed: [
          "Maintain accurate, easy-to-update listing details",
          "Demonstrate authority with informative white papers, press releases and industry announcements",
          "Cultivate your brand's image by sharing blog posts, webinars and podcasts",
        ],
        trust: [
          "Stay fresh with access to new content uploads from your custom dashboard",
          "Magnify your credibility by featuring your most relevant industry content",
          "Share customer testimonials and reviews",
        ],
        grow: [
          "Connect with warmer leads by targeting in-market buyers",
          "Link directly to your site from products and services",
          "Boost your organic search results",
          "Generate quality leads when buyers interact with your content",
        ],
      },
      fullFeatures: [
        {
          title: "Category Listing",
          features: [
            {
              title: "First Page Placement in Relevant Categories",
              plans: { featured: true },
            },
            {
              title: "Company Logo",
              plans: { featured: true },
            },
            {
              title: "Link to Website",
              plans: { featured: true },
            },
            {
              title: "Link to Product/Service Library",
              plans: { featured: true },
            },
            {
              title: "Link to Content Library",
              plans: { featured: true },
            },
            {
              title: "Category Selection",
              plans: { featured: "unlimited", optimized: 10, basic: 1 },
            },
            {
              title: "First Position in category",
              plans: { featured: "$" },
            },
          ],
        },
        {
          title: "Company Profile",
          features: [
            {
              title: "Company Description",
              plans: { featured: true, optimized: true, basic: true },
            },
            {
              title: "Company Logo",
              plans: { featured: true, optimized: true },
            },
            {
              title: "Phone Number",
              plans: { featured: true, optimized: true },
            },
            {
              title: "Links to Social Media Pages ",
              plans: { featured: true, optimized: true },
            },
            {
              title: "Link to Company Website",
              plans: { featured: true, optimized: true },
            },
            {
              title: "Trade Shows & Events Calendar",
              plans: { featured: true },
            },
            {
              title: "White Papers",
              plans: { featured: "unlimited", optimized: 5, basic: 2 },
            },
            {
              title: "Press Releases",
              plans: { featured: "unlimited", optimized: 5 },
            },
            {
              title:
                "Custom Content Sections (Case Studies, Podcasts, Videos, etc)",
              plans: { featured: "unlimited" },
            },
            {
              title: "Product & Service listings",
              plans: { featured: "unlimited", optimized: 5, basic: 2 },
            },
            {
              title: "Remove Alternative Company Recommendation Box",
              plans: { featured: true },
            },
          ],
        },
        {
          title: "Enhanced Visibility",
          features: [
            {
              title: "Rotational Placement on Weekly Newsletter",
              plans: { featured: true },
            },
            {
              title: "Appear on Competitors' Profiles",
              plans: { featured: true },
            },
            {
              title: "Banner Ad",
              plans: { featured: "$" },
            },
            {
              title: "Content Boost",
              plans: { featured: "$" },
            },
            {
              title: "Brand Boost",
              plans: { featured: "$" },
            },
            {
              title: "Solutions Brief",
              plans: { featured: "$" },
            },
          ],
        },
        {
          title: "Lead Generation Tools",
          features: [
            {
              title: "White Paper Leads",
              plans: { featured: "unlimited", optimized: 3 },
            },
            {
              title: "Custom Content Leads",
              plans: { featured: "unlimited" },
            },
            {
              title: "Product & Service Inquires",
              plans: { featured: "unlimited" },
            },
            {
              title: "Content Recommendation Inclusion",
              plans: { featured: true, optimized: true },
            },
            {
              title: "Content Promotion in Weekly Newsletter",
              plans: { featured: true, optimized: true },
            },
            {
              title: "Zapier API Integration",
              plans: { featured: true },
            },
          ],
        },
        {
          title: "Performance Reporting",
          features: [
            {
              title: "Visitor Insights Report",
              plans: { featured: true },
            },
            {
              title: "Audience Report",
              plans: { featured: true, optimized: true, basic: true },
            },
            {
              title: "Profile Performance Report",
              plans: { featured: true, optimized: true },
            },
          ],
        },
        {
          title: "Business Chatter - Reputation Management Platform ",
          features: [
            {
              title: "Capture Reviews",
              plans: { featured: true, optimized: true, basic: true },
            },
            {
              title:
                "Intercept Negative Feedback Before It's Publicly Available",
              plans: { featured: true, optimized: true },
            },
            {
              title: "Solicit Reviews",
              plans: { featured: "unlimited", optimized: 20 },
            },
            {
              title: "Dedicated Link for Review Capture",
              plans: { featured: true, optimized: true, basic: true },
            },
            {
              title: "QR Code for Review Capture",
              plans: { featured: true, optimized: true },
            },
            {
              title: "Compare Your Reputation to Competitors",
              plans: { featured: 10, optimized: 5, basic: 2 },
            },
            {
              title: "Channel Partner / Authorized Dealer List",
              plans: { featured: "$" },
            },
          ],
        },
        {
          title: "Support",
          features: [
            {
              title: "Professional Profile Setup",
              plans: { featured: true, optimized: true },
            },
          ],
        },
      ],
      features: {
        basic: [
          "Accurate company details in a relevant industry category",
          "Custom company description",
          "List your white papers",
          "List your products and services",
          "View audience reports",
          "Capture customer reviews",
        ],
        optimized: [
          "Additional market categories",
          "Company logo on profile page",
          "Company contact information",
          "Active company links",
          "Additional content types",
          "Content promotions",
          "More product listings",
          "Leads from your content",
          "Profile performance reports",
          "Reputation management tools",
        ],
        featured: [
          "Unlimited market categories",
          "Company logo on listing page",
          "Your trade show & event calendar",
          "Unlimited content sharing",
          "Unlimited product listings",
          "Advanced content selections",
          "Content promotions",
          "Enhanced visibility",
          "Unlimited leads",
          "Advanced performance reporting",
          "Unlimited review generating",
          "Google My Business integration",
        ],
        custom: [
          "Run of site banner advertising",
          "Featured company profile placement",
          "First category listing position",
          "Social media advertising",
        ],
      },
      noPlan: false,
      currentPlan: {
        planName: null,
      },
      selected: null,
      cart: null,
      loaded: false,
      upgradePreview: null,
      site: null,
      buying: false,
      rep: null,
      originalPath: null,
      observer: null,
      topComponent: null,
      title: "Manage Your Subscription",
      checkoutButton: "Upgrade now",
      incident: null,
      showTrial: false,
    };
  },
  computed: {
    plan() {
      return this.plans[this.selectedPlan];
    },
    showMonthlyPrice() {
      return this.duration == "monthly";
    },
    frequency() {
      return this.plan.frequencyMessage;
    },
    trial() {
      return this.showTrial && this.duration == "monthly";
    },
  },
  methods: {
    hidden(plan) {
      return this.hide.includes(plan);
    },
    contact() {
      this.$bvModal.show("book-demo-modal");
    },
    money(price, digits) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: digits, // (causes 2500.99 to be printed as $2,501)
      });

      return formatter.format(price); /* $2,500.00 */
    },
    async loadSitePricing(site) {
      let pricing = await SiteService.getSitePricing(site);

      this.products.optimized.monthlyPrice = pricing.optimizedMonthly;
      this.products.optimized.annualPrice = pricing.optimizedAnnual;

      this.products.featured.monthlyPrice = pricing.featuredMonthly;
      this.products.featured.annualPrice = pricing.featuredAnnual;

      this.site = await SiteService.getSite(site);
    },
    clearCart() {
      this.selected = this.currentPlan.planName;
      this.cart = null;
      this.upgradePreview = null;
    },
    changeDuration(duration) {
      this.clearCart();
      this.duration = duration;
      this.selectedPlan = duration;
    },
    selectFreeNoPlan() {
      window.parent.postMessage("done", "*");
      this.selected = "basic";
    },
    async addToCart(plan) {
      this.selected = plan;
      if (this.currentPlan.planName == plan) {
        TrackingService.track("removed a plan from the cart", {
          siteId: this.site.siteid,
          detail: plan,
        });

        this.cart = null;
      } else {
        TrackingService.track("added a plan to the cart", {
          siteId: this.site.siteid,
          detail: plan,
        });

        this.cart = {
          planName: plan,
          duration: this.duration,
          price:
            (this.duration == "monthly"
              ? this.products[plan].monthlyPrice
              : this.products[plan].annualPrice) / this.plan.cartDivisor,
          per: this.plan.per,
          trial: this.trial,
        };

        let currentIO = null;
        if (this.currentPlan.insertionOrder) {
          currentIO = this.currentPlan.insertionOrder.insertionorderid;
        }

        try {
          this.upgradePreview = await InsertionOrderService.getUpgradePreview(
            this.$auth.activeCompany.companyId,
            this.site.siteid,
            `${plan}${this.plan.productSlug}`,
            this.plan.durationMonths,
            currentIO,
            this.trial
          );
        } catch (e) {
          this.clearCart();
          this.$bvModal.show("upgrade-unavailable-modal");
          TrackingService.track("could not complete upgrade", {
            siteId: this.site.siteid,
            detail: plan,
          });
        }

        if (!this.upgradePreview) {
          this.clearCart();
          this.$bvModal.show("upgrade-unavailable-modal");
          TrackingService.track("could not complete upgrade", {
            siteId: this.site.siteid,
            detail: plan,
          });
        }
      }
    },
    async checkout() {
      if (!this.cart) return;
      this.buying = true;
      let rep = null;
      if (this.$auth.superuser && window.localStorage.getItem("demo-as-rep")) {
        rep = parseInt(window.localStorage.getItem("demo-as-rep"));
      }
      let invoice = await UpsellService.generateInvoice(
        this.$auth.activeCompany.companyId,
        this.site.siteid,
        `${this.selected}${this.plan.productSlug}`,
        null,
        this.incident,
        this.originalPath,
        this.plan.durationMonths,
        rep,
        this.trial
      );

      TrackingService.track("checkout", {
        siteId: this.site.siteid,
        detail: `${this.selected}${this.plan.productSlug}`,
      });
      this.$router.push(`/billing/paybycc?invoiceid=${invoice.invoiceid}`);
    },

    async redirectIfHasInvoice() {
      let insertionOrders = await BillingService.getCurrentInsertionOrders(
        this.$auth.activeCompany
      );

      if (insertionOrders.length == 0) {
        return;
      }

      let siteIos = insertionOrders.filter((io) =>
        io.sites.map((s) => s.siteid).includes(this.site.siteid)
      );

      if (siteIos.length > 0) {
        let io = siteIos[0];
        this.$router.replace(
          `/newupgrade?io=${io.insertionOrder.insertionorderid}`
        );
        await this.loadIo(io.insertionOrder.insertionorderid);
      }
    },

    async loadSite(site) {
      this.currentPlan = {
        planName: "basic",
      };
      this.selected = "basic";
      await this.loadSitePricing(site);

      TrackingService.track("opened upgrade page for site", {
        siteId: site,
      });
    },

    async loadIo(io) {
      this.currentPlan = await InsertionOrderService.getInsertionOrderDetail(
        io,
        this.$auth.activeCompany
      );

      let site = this.currentPlan.sites[0];
      await this.loadSitePricing(site.siteid);

      TrackingService.track("opened upgrade page for existing plan", {
        siteId: site.siteid,
        detail: this.currentPlan.planName,
      });

      this.selected = this.currentPlan.planName;
      if (this.currentPlan.billingFrequencyMonths == 1) {
        this.duration = "monthly";
      }
    },

    async loadRep(rep) {
      if (rep) {
        this.rep = await BillingService.getCompanyRep(
          this.$auth.activeCompany,
          rep
        );
      } else {
        this.rep = await BillingService.getCompanyRep(this.$auth.activeCompany);
      }
    },

    observeHeightChanges() {
      if (!this.observer) {
        const resizeObserver = new ResizeObserver((entries) => {
          window.parent.postMessage(
            JSON.stringify({ height: entries[0].target.clientHeight }),
            "*"
          );
        });

        this.observer = resizeObserver.observe(document.body);
      }
    },

    showAnchor() {
      if (this.$route.query.anchor) {
        this.$nextTick(() => {
          this.$refs[this.$route.query.anchor].scrollIntoView();
          this.scrollHidden = false;
        });
      }
    },
  },
  async mounted() {
    if (!this.originalPath) {
      this.originalPath = this.$route.fullPath;
    }

    if (this.$route.query.trial) {
      this.showTrial = true;
    }

    if (this.$route.query.hide) {
      this.$route.query.hide.split(",").forEach((h) => {
        this.hide.push(h);
      });
    }

    if (this.$route.query.incident) {
      this.incident = this.$route.query.incident;
    }

    if (this.$route.query.showTop) {
      this.showTop = true;
    }

    if (this.$route.query.plans) {
      this.availablePlans = this.$route.query.plans.split(",");
      this.changeDuration(this.availablePlans[this.availablePlans.length - 1]);
    } else {
      this.changeDuration("annual");
    }

    if (this.$route.query.defaultPlan) {
      this.changeDuration(this.$route.query.defaultPlan);
    }

    if (this.$route.query.showPricing === "false") {
      this.showPricing = false;
    }

    if (this.$route.query.io) {
      // tracked in load io
      await this.loadIo(this.$route.query.io);
    } else if (this.$route.query.site) {
      await this.loadSite(this.$route.query.site);
    } else {
      this.showTop = true;
      let company = this.$auth.activeCompany;

      let site = 170;
      let topSite = company.sites
        .filter((s) => s.listingType == "free")
        .sort((a, b) => {
          return a.siteRank - b.siteRank;
        });
      if (topSite.length > 0) {
        site = topSite[0].siteID;
      }
      await this.loadSite(site);
    }

    if (this.$route.query.plan) {
      this.addToCart(this.$route.query.plan);
    }

    if (!this.$route.query.io) {
      await this.redirectIfHasInvoice();
    }

    if (this.$route.query.top) {
      if (this.$route.query.top == "add-your-company") {
        this.topComponent = this.$route.query.top;
      } else if (this.$route.query.top == "verify") {
        this.topComponent = this.$route.query.top;
      } else if (this.$route.query.top == "six-month") {
        this.topComponent = this.$route.query.top;
      } else if (this.$route.query.top == "six-month-orange") {
        this.topComponent = this.$route.query.top;
      } else if (this.$route.query.top == "verify-offer") {
        this.topComponent = this.$route.query.top;
      } else if (this.$route.query.top == "custom") {
        this.topComponent = this.$route.query.top;
      }
    }

    if (this.$route.query.title) {
      this.title = this.$route.query.title;
    }

    if (this.$route.query.checkout) {
      this.checkoutButton = this.$route.query.checkout;
    }

    this.loadRep();

    if (window.parent) {
      this.observeHeightChanges();
    }

    this.selectedPlan = this.duration;
    if (this.$route.query.noPlan) {
      this.noPlan = true;
      this.currentPlan = {
        planName: null,
      };
      this.selected = null;
    }

    if (this.$route.query.anchor) {
      this.scrollHidden = true;
    }
    this.$nextTick(() => {
      this.loaded = true;
      this.showAnchor();
    });
  },
};
</script>
