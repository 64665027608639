<template>
  <div>
    <table class="position-relative upgrades-table">
      <thead class="position-sticky t-0 bg-white sticky-top">
        <tr>
          <th></th>
          <th class="plan" v-for="plan in plans" :key="`title-plan-${plan}`">
            <template v-if="plans.length > 1">{{ plan }}</template>
          </th>
        </tr>
      </thead>

      <tbody
        v-for="(section, idx) in showUpgrades"
        :key="`upgrade-table-section-${idx}`"
      >
        <tr>
          <td :colspan="1 + plans.length">
            <div
              @click.prevent="hide(section)"
              class="section-title-row d-flex align-items-center"
            >
              <span class="me-2">{{ section.title }}</span>

              <span
                :class="[
                  plans.length > 1 ? `color-optimized` : `color-${plans[0]}`,
                ]"
              >
                <svg
                  v-if="hidden(section)"
                  xmlns="http://www.w3.org/2000/svg"
                  :class="['icon', `icon-color`]"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <desc>
                    Download more icon variants from
                    https://tabler-icons.io/i/chevron-down
                  </desc>
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <polyline points="6 9 12 15 18 9"></polyline>
                </svg>
                <svg
                  v-else
                  xmlns="http://www.w3.org/2000/svg"
                  :class="['icon', `icon-color`]"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <desc>
                    Download more icon variants from
                    https://tabler-icons.io/i/chevron-up
                  </desc>
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <polyline points="6 15 12 9 18 15"></polyline>
                </svg>
              </span>
            </div>
          </td>
        </tr>

        <tr
          v-for="(feature, fIdx) in sectionFeatures(section)"
          :key="`section-${idx}-feature-${fIdx}`"
          class="feature-row"
        >
          <td class="feature">
            <div>
              {{ feature.title }}
            </div>
          </td>
          <td
            v-for="plan in plans"
            :key="`section-${idx}-feature-${fIdx}-plan-${plan}`"
            :class="[`color-${plan}`, 'plan-included']"
          >
            <div class="me-3 me-lg-0">
              <span v-if="feature.plans[plan] === true">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="white"
                  fill="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <circle cx="12" cy="12" r="9"></circle>
                  <path d="M9 12l2 2l4 -4"></path>
                </svg>
              </span>
              <span
                v-else-if="
                  feature.plans[plan] === undefined ||
                  feature.plans[plan] === null
                "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  v-if="plans.length == 1"
                  class="icon text-muted"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M5 12h18"></path>
                </svg>
              </span>
              <span v-else>
                {{ feature.plans[plan] }}
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="4"><div class="divider"></div></td>
        </tr>
      </tbody>

      <thead
        v-if="plans.length > 1"
        class="position-sticky bg-white sticky-bottom up-shadow"
      >
        <tr>
          <th></th>
          <th class="plan" v-for="plan in plans" :key="`title-plan-${plan}`">
            <template v-if="plans.length > 1 && plan != 'basic'">
              <a
                href="#"
                @click.prevent="$emit('cart', plan)"
                :class="['btn', `btn-${plan}`, 'my-3']"
                v-if="plan !== current"
              >
                Select Plan
              </a>

              <a
                href="#"
                @click.prevent=""
                :class="['btn', `btn-${plan}`, 'my-3', 'disabled']"
                disabled
                v-else
              >
                Current Plan
              </a>
            </template>
          </th>
        </tr>
      </thead>
    </table>
  </div>
</template>

<style lang="scss" scoped>
.color-featured {
  color: #f68b26;
}

.btn-featured {
  background: #f68b26;
  border-color: #f58318;
  color: white;

  &:hover {
    border-color: white;
  }
}

.color-optimized {
  color: #15c39a;
}

.btn-optimized {
  background: #15c39a;
  border-color: #13b992;
  color: white;

  &:hover {
    border-color: white;
  }
}

.color-basic {
  color: #2f8dde;
}

.btn-basic {
  background: #2f8dde;
  border-color: #2386dc;
  color: white;

  &:hover {
    border-color: white;
  }
}

.up-shadow {
  box-shadow: 0 -6px 4px -4px rgba(0, 0, 0, 0.25);
}

table {
  width: 100%;

  thead {
    &.sticky-top {
      box-shadow: 0px 1px 0px #cdd3dd;
    }

    &.sticky-bottom {
      bottom: 0px;
    }

    .plan {
      text-align: center;
      text-transform: capitalize;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
    }
  }

  tbody {
    .section-title-row {
      background: #f1f2f3;
      box-shadow: 0px 1px 0px #cdd3dd;
      padding: 10px 16px;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
    }

    .feature-row {
      background: #ffffff;
      border-bottom: solid 1px #cdd3dd;
    }

    .feature {
      div {
        padding: 10px 16px;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
      }
    }

    .plan-included {
      text-align: center;
    }

    .divider {
      height: 24px;
    }
  }
}
</style>

<script>
export default {
  name: "upgrade.upgrades-table",
  props: {
    upgrades: Array,
    forPlan: String,
    current: String,
  },
  data() {
    return {
      hiddenSections: [],
    };
  },
  computed: {
    plans() {
      if (this.forPlan == "all") {
        return ["basic", "optimized", "featured"];
      } else if (this.forPlan.indexOf(",") >= 0) {
        return this.forPlan.split(",");
      } else {
        return [this.forPlan];
      }
    },
    showUpgrades() {
      if (this.forPlan == "all") return this.upgrades;

      return this.upgrades;
    },
  },
  methods: {
    hidden(section) {
      return this.hiddenSections.includes(section.title);
    },
    sectionSlug(section) {
      return section.trim().toLowerCase().split(" ").join("-");
    },
    hide(section) {
      if (this.hiddenSections.includes(section.title)) {
        this.hiddenSections.splice(
          this.hiddenSections.indexOf(section.title),
          1
        );
      } else {
        this.hiddenSections.push(section.title);
      }
    },
    sectionFeatures(section) {
      if (this.hiddenSections.includes(section.title)) {
        return [];
      } else {
        return section.features;
      }
    },
  },
};
</script>
