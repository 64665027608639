<template>
  <div class="bg-white shadow-text py-3">
    <div class="container">
      <div class="row align-items-center justify-content-between">
        <div
          class="col-lg-4 d-none d-lg-flex flex-column align-items-center justify-content-center"
        >
          <img
            :src="`https://cdn.mediabrains.com/directorylogos/${site.projectname}_pubhome.gif`"
            class="mb-2 mb-lg-4"
          />

          <div class="d-none alert alert-success">
            <a
              class="center-text"
              target="_blank"
              :href="`https://${site.directoryurl}/company/${$auth.activeCompany.companyId}`"
            >
              View your updated

              <span class="verified">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="me-2"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M9 12l2 2l4 -4"></path>
                  <path
                    d="M12 3a12 12 0 0 0 8.5 3a12 12 0 0 1 -8.5 15a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3"
                  ></path>
                </svg>
                <strong>Verified</strong>
              </span>

              listing &raquo;
            </a>
          </div>
        </div>
        <div
          class="col-lg-8 d-flex flex-column align-items-center justify-content-center"
        >
          <h1 class="text-center">$0 for your first month</h1>

          <h1 class="text-center mb-blue bigger constrain pb-3">
            Get all the benefits of a <span>Featured Listing</span> FREE for 30
            days
          </h1>

          <div
            class="d-flex align-items-center justify-content-center countdown pb-3"
            v-if="future(endDate)"
          >
            <div class="me-4">
              <h2 class="text-center mb-0">{{ countdown.days }}</h2>
              <span class="subheader text-center">DAYS</span>
            </div>
            <div class="me-4">
              <h2 class="text-center mb-0">{{ countdown.hours }}</h2>
              <span class="subheader text-center">HOURS</span>
            </div>
            <div class="me-4">
              <h2 class="text-center mb-0">{{ countdown.minutes }}</h2>
              <span class="subheader text-center">MINUTES</span>
            </div>
            <div class="">
              <h2 class="text-center mb-0">{{ countdown.seconds }}</h2>
              <span class="subheader text-center">SECONDS</span>
            </div>
          </div>

          <p class="text-center text-muted">
            Offer expires
            {{ prettyDate(endDate) }}.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.verified {
  background: rgba(25, 135, 34, 0.1);
  border-radius: 8px;
  padding: 6px;
  display: inline-flex;
  align-items: center;
  color: rgb(25, 135, 34);
  border: solid 1px rgb(25, 135, 34);
  margin-left: 8px;
  margin-right: 8px;
}

.center-text {
  display: flex;
  align-items: center;
}

.vh100 {
  height: 100vh;
}

.countdown {
  h2 {
    line-height: 20px !important;
  }
  .subheader {
    font-size: 14px !important;
    line-height: 20px !important;
  }
}

h1 {
  font-weight: bolder;
}
</style>

<script>
import {
  parse,
  format,
  differenceInSeconds,
  isFuture,
  setHours,
} from "date-fns";

export default {
  name: "upgrade.tops.six-month",
  props: {
    price: Number,
    site: Object,
  },
  data() {
    return {
      features: [
        "Unlimited market categories",
        "Company logo on listing page",
        "Your trade show & event calendar",
        "Unlimited content sharing",
        "Unlimited product listings",
        "Advanced content selections",
        "Content promotions",
        "Enhanced visibility",
        "Unlimited leads",
        "Advanced performance reporting",
        "Unlimited review generating ",
      ],
      endDate: null,
      countdown: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      timer: null,
    };
  },
  methods: {
    prettyDate(date) {
      if (date) {
        return format(date, "MMMM do, yyyy");
      }
    },
    future(date) {
      if (date) {
        return isFuture(date);
      } else {
        return false;
      }
    },
    updateCountdown() {
      const diffInSeconds = differenceInSeconds(
        this.endDate,
        new Date().getTime()
      );
      const ONE_DAY = 60 * 60 * 24;
      const ONE_HOUR = 60 * 60;
      const ONE_MINUTE = 60;
      if (diffInSeconds <= 1) {
        this.countdown = {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        };
      }
      const days = Math.floor(diffInSeconds / ONE_DAY);
      const hours = Math.floor((diffInSeconds - days * ONE_DAY) / ONE_HOUR);
      const minutes = Math.floor(
        (diffInSeconds - days * ONE_DAY - hours * ONE_HOUR) / ONE_MINUTE
      );
      const seconds =
        diffInSeconds -
        days * ONE_DAY -
        hours * ONE_HOUR -
        minutes * ONE_MINUTE;
      this.countdown = {
        days,
        hours,
        minutes,
        seconds,
      };
    },
  },
  mounted() {
    if (this.$route.query.end) {
      this.endDate = setHours(
        parse(this.$route.query.end, "MM-dd-yyyy", new Date()),
        17
      );
      if (!this.timer) {
        this.updateCountdown();
        setInterval(() => this.updateCountdown(), 1000);
      }
    }
  },
};
</script>
