var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white shadow-text py-3"},[_c('div',{staticClass:"container"},[_c('div',{class:[
        'row',
        'align-items-center',
        _vm.override('logo', 'show', '1', true)
          ? 'justify-content-between'
          : 'justify-content-center' ]},[(_vm.override('logo', 'show', '1', true))?_c('div',{staticClass:"col-lg-4 d-none d-lg-flex flex-column align-items-center justify-content-center"},[_c('img',{staticClass:"mb-2 mb-lg-4",attrs:{"src":("https://cdn.mediabrains.com/directorylogos/" + (_vm.site.projectname) + "_pubhome.gif")}})]):_vm._e(),_c('div',{staticClass:"col-lg-8 d-flex flex-column align-items-center justify-content-center"},[(_vm.override('extra', 'show', '1', true))?_c('h1',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.renderTemplate( _vm.override("extra", "template", "$0 for your first month"), { date: _vm.prettyDate(_vm.endDate) } ))+" ")]):_vm._e(),(_vm.override('main', 'show', '1', true))?_c('h1',{staticClass:"text-center mb-blue bigger constrain pb-3"},[_vm._v(" "+_vm._s(_vm.renderTemplate( _vm.override( "main", "template", "Get all the benefits of a Featured Listing FREE for 30 days" ), { date: _vm.prettyDate(_vm.endDate) } ))+" ")]):_vm._e(),(_vm.override('small', 'show', '0', true))?_c('p',{staticClass:"h3"},[_vm._v(" "+_vm._s(_vm.renderTemplate( _vm.override( "small", "template", "This is an additional element you can control!" ), { date: _vm.prettyDate(_vm.endDate) } ))+" ")]):_vm._e(),(_vm.override('link.top', 'show', '0', true))?_c('p',{class:['text-center'].concat(
              _vm.override('link.top', 'style', '').split(' ')
            )},[_c('a',{attrs:{"href":_vm.renderTemplate(
                _vm.override('link.top.href', 'template', 'https://google.com'),
                { date: _vm.prettyDate(_vm.endDate) }
              )}},[_vm._v(" "+_vm._s(_vm.renderTemplate( _vm.override( "link.top", "template", "This is an additional element you can control!" ), { date: _vm.prettyDate(_vm.endDate) } ))+" ")])]):_vm._e(),(_vm.override('countdown', 'show', '1', true) && _vm.future(_vm.endDate))?_c('div',{staticClass:"d-flex align-items-center justify-content-center countdown pb-3"},[_c('div',{staticClass:"me-4"},[_c('h2',{staticClass:"text-center mb-0"},[_vm._v(_vm._s(_vm.countdown.days))]),_c('span',{staticClass:"subheader text-center"},[_vm._v("DAYS")])]),_c('div',{staticClass:"me-4"},[_c('h2',{staticClass:"text-center mb-0"},[_vm._v(_vm._s(_vm.countdown.hours))]),_c('span',{staticClass:"subheader text-center"},[_vm._v("HOURS")])]),_c('div',{staticClass:"me-4"},[_c('h2',{staticClass:"text-center mb-0"},[_vm._v(_vm._s(_vm.countdown.minutes))]),_c('span',{staticClass:"subheader text-center"},[_vm._v("MINUTES")])]),_c('div',{},[_c('h2',{staticClass:"text-center mb-0"},[_vm._v(_vm._s(_vm.countdown.seconds))]),_c('span',{staticClass:"subheader text-center"},[_vm._v("SECONDS")])])]):_vm._e(),(_vm.override('expires', 'show', '1', true) && _vm.endDate)?_c('p',{staticClass:"text-center text-muted"},[_vm._v(" "+_vm._s(_vm.renderTemplate( _vm.override("expires", "template", "Offer expires [date]."), { date: _vm.prettyDate(_vm.endDate) } ))+" ")]):_vm._e(),(_vm.override('link.bottom', 'show', '0', true))?_c('p',{class:['text-center'].concat(
              _vm.override('link.bottom', 'style', '').split(' ')
            )},[_c('a',{attrs:{"href":_vm.renderTemplate(
                _vm.override(
                  'link.bottom.href',
                  'template',
                  'https://google.com'
                ),
                { date: _vm.prettyDate(_vm.endDate) }
              )}},[_vm._v(" "+_vm._s(_vm.renderTemplate( _vm.override( "link.bottom", "template", "This is an additional element you can control!" ), { date: _vm.prettyDate(_vm.endDate) } ))+" ")])]):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }