<template>
  <div class="card info-card">
    <div class="card-body">
      <img class="icon mb-3 mt-3" :src="iconUrl" />
      <h2>{{ title }}</h2>
      <ul>
        <li
          v-for="(callout, idx) in callouts"
          :key="`callout-${titleSlug}-${idx}`"
        >
          {{ callout }}
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.info-card {
  box-shadow: 1px 2px 4px #cdd3dd;
  border-radius: 16px;
  border: none;

  .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;

    .icon {
      width: 48px;
      height: 48px;
    }

    ul {
      li {
        margin-bottom: 8px;

        &::marker {
          color: #f68b26;
        }
      }
    }
  }
}
</style>

<script>
export default {
  name: "upgrade.callout-card",
  props: {
    title: String,
    callouts: Array,
    icon: String,
  },
  computed: {
    titleSlug() {
      return this.title.split(" ").join("-").toLowerCase();
    },
    iconUrl() {
      return require(`@/assets/lp/${this.icon}.png`);
    },
  },
};
</script>
